import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import HowToSupport from "../src/components/docs/how-to-support";
export const _frontmatter = {
  "name": "How to support your favorite creators",
  "route": "/how-to-support-your-favorite-creators"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <HowToSupport mdxType="HowToSupport" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      